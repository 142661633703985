import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VuejsDialog from 'vuejs-dialog';

import firebase from "firebase";
import "firebase/analytics";

//Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyC6GmLc2lMMp6y4SsWH6jg5V90jqIZP-uU",
  authDomain: "ghata-xyz.firebaseapp.com",
  projectId: "ghata-xyz",
  storageBucket: "ghata-xyz.appspot.com",
  messagingSenderId: "411742418987",
  appId: "1:411742418987:web:17facb3430372b3344d26e",
  measurementId: "G-8ZD122M95Q"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();  // alias


// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog);

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  // Set Dynamic title to eache page
  let pageTitle = to.name || 'Home';
  document.title = pageTitle;

  //Send Firebase events
  firebase.analytics().setCurrentScreen(pageTitle);
  firebase.analytics().logEvent('page_view', { type: 'internal' });
  firebase.analytics().logEvent("screen_view", {
    app_name: "ghata.xyz",
    screen_name: pageTitle,
    app_version: "1.0"
  });
  
  next();
}) 

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
