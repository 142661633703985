<template>
  <div class="home">
    <div class="header-intro">
      <div class="section_overlay">
        <div class="container">
          <div class="row">
              <div class="col-md-12 text-center">
                <div class="home_text wow fadeInUp animated">
                    <h2>it’s Krishnendu Ghata</h2>
                    <p style="color: #000;">a software architect, cloud specialist and data science enthusiast</p>
                    <img src="/images/shape.png" alt="">                        
                </div>
              </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
              <div class="col-md-12 text-center">
                <div class="scroll_down">
                    <a href="#SERVICE"><img src="/images/scroll.png" alt=""></a>
                    <h4>Scroll Down</h4>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services" id="SERVICE">
      <div class="container">
          <div class="row">
            <div class="col-md-3 text-center">
                <div class="single_service wow fadeInUp" data-wow-delay="1s">
                  <i class="icon-pencil"></i>
                  <h2>System Design</h2>
                  <p>10+ Years of experience in system design and software engineering.</p>
                </div>
            </div>
            <div class="col-md-3 text-center">
                <div class="single_service wow fadeInUp" data-wow-delay="2s">
                  <i class="icon-gears"></i>
                  <h2>Development</h2>
                  <p>Experience in end to end software development with multiple language and frameworks.</p>
                </div>
            </div>
            <div class="col-md-3 text-center">
                <div class="single_service wow fadeInUp" data-wow-delay="3s">
                  <i class="icon-magnifying-glass"></i>
                  <h2>Data Science</h2>
                  <p>Budding data scientist exploring the ocean of opportunities.</p>
                </div>
            </div>
            <div class="col-md-3 text-center">
                <div class="single_service wow fadeInUp" data-wow-delay="4s">
                  <i class="icon-camera"></i>
                  <h2>Photography</h2>
                  <p>A picture is worth a thousand words...</p>
                </div>
            </div>
          </div>
      </div>
    </section>
    <section class="about_us_area" id="ABOUT">
      <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
                <div class="about_title">
                  <h2>About Me</h2>
                  <img src="images/shape.png" alt="">
                </div>
            </div>
          </div>
      </div>
      <div class="container">
          <div class="row">
            <div class="col-md-4  wow fadeInLeft animated">
                <div class="single_progress_bar">
                  <h2>Development</h2>
                  <div class="progress">
                      <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">
                        <span class="sr-only">60% Complete</span>
                      </div>
                  </div>
                </div>
                <div class="single_progress_bar">
                  <h2>Data Science</h2>
                  <div class="progress">
                      <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">
                        <span class="sr-only">60% Complete</span>
                      </div>
                  </div>
                </div>
            </div>
            <div class="col-md-4  wow fadeInRight animated">
                <p class="about_us_p">I have a solid 10+ year track record of steering the development, implementation, deployment, and optimization of infrastructure architectures to achieve maximum uptime across large, complex, and global IT environments.</p>
            </div>
            <div class="col-md-4  wow fadeInRight animated">
                <p class="about_us_p">A Data Scientist is a detective, just like Sherlock Holmes! The only difference is, Sherlock Holmes solves murder mysteries, whereas a Data Scientist solves Data mysteries, such as detecting trends and patterns, extracting useful insights, forming hypotheses and building predictive models in order to find a solution.</p>
            </div>
          </div>
      </div>
    </section>
   
    <!-- <div class="fun_facts">
      <section class="header parallax home-parallax page" id="fun_facts" style="background-position: 50% -150px;">
          <div class="section_overlay">
            <div class="container">
                <div class="row">
                  <div class="col-md-6 wow fadeInLeft animated">
                      <div class="row">
                        <div class="col-md-4">
                            <div class="single_count">
                              <i class="icon-toolbox"></i>
                              <h3>300</h3>
                              <p>Project Done</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single_count">
                              <i class="icon-clock"></i>
                              <h3>1700+</h3>
                              <p>Hours Worked</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single_count">
                              <i class="icon-trophy"></i>
                              <h3>37</h3>
                              <p>Awards Won</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-5 col-md-offset-1 wow fadeInRight animated">
                      <div class="imac">
                        <img src="images/imac.png" alt="">
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </section>
    </div>
    <section class="work_area" id="WORK">
      <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
                <div class="work_title  wow fadeInUp animated">
                  <h1>Latest Works</h1>
                  <img src="images/shape.png" alt="">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna <br> aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.</p>
                </div>
            </div>
          </div>
      </div>
      <div class="container-fluid">
          <div class="row">
            <div class="col-md-4 no_padding">
                <div class="single_image">
                  <img src="images/w1.jpg" alt="">
                  <div class="image_overlay">
                      <a href="">View Full Project</a>
                      <h2>drawing</h2>
                      <h4>with pencil colors</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-4 no_padding">
                <div class="single_image">
                  <img src="images/w2.jpg" alt="">
                  <div class="image_overlay">
                      <a href="">View Full Project</a>
                      <h2>drawing</h2>
                      <h4>with pencil colors</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-4 no_padding">
                <div class="single_image">
                  <img src="images/w3.jpg" alt="">
                  <div class="image_overlay">
                      <a href="">View Full Project</a>
                      <h2>drawing</h2>
                      <h4>with pencil colors</h4>
                  </div>
                </div>
            </div>
          </div>
          <div class="row pad_top">
            <div class="col-md-4 no_padding">
                <div class="single_image">
                  <img src="images/w4.jpg" alt="">
                  <div class="image_overlay">
                      <a href="">View Full Project</a>
                      <h2>drawing</h2>
                      <h4>with pencil colors</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-4 no_padding">
                <div class="single_image">
                  <img src="images/w5.jpg" alt="">
                  <div class="image_overlay">
                      <a href="">View Full Project</a>
                      <h2>drawing</h2>
                      <h4>with pencil colors</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-4 no_padding">
                <div class="single_image last_padding">
                  <img src="images/w6.jpg" alt="">
                  <div class="image_overlay">
                      <a href="">View Full Project</a>
                      <h2>drawing</h2>
                      <h4>with pencil colors</h4>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </section> -->

    <section class="technology_slides">
      <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
                <div class="about_title">
                  <h2>Technologies I Use</h2>
                  <img src="images/shape.png" alt="">
                </div>
            </div>
          </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 wow">
            <carousel :nav="false" :dots="false" :autoplay="true" :loop="true" :autoWidth="true" :autoplayHoverPause="true">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/1.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/2.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/3.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/4.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/5.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/6.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/7.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/8.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/9.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/10.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/11.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/12.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/13.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/14.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/15.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/16.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/17.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/18.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/19.png">
              <img style="width: 180px; margin: 0 5px 0 5px;" src="images/slides/20.png">
            </carousel>
          </div>
        </div>
      </div>
    </section>
    
    <section style="height: 100px;">
      
    </section>

    <section class="call_to_action">
      <div class="container">
          <div class="row">
            <div class="col-md-8 wow fadeInLeft animated">
                <div class="left">
                  <h2>LOOKING FOR A TEAM TO MAKE YOUR NEW MILLION-DOLLAR IDEA A REALITY</h2>
                  <p>Do you have a million-dollar idea but aren't sure how to make it a reality? 
                    I lead a team that has experience in more than 100+ application development.
                  </p>
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1 wow fadeInRight animated">
                <div class="baton">
                  <a href="#CONTACT">
                    <button type="button" class="btn btn-primary cs-btn">Let's Talk</button>
                  </a>    
                </div>
            </div>
          </div>
      </div>
    </section>
    <section class="contact" id="CONTACT">
      <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
                <div class="contact_title  wow fadeInUp animated">
                  <h1>get in touch</h1>
                  <img src="images/shape.png" alt="">
                </div>
            </div>
          </div>
      </div>
      <div class="container">
          <div class="row">
            <div class="col-md-3  wow fadeInLeft animated">
                <!-- <div class="single_contact_info">
                  <h2>Call Me</h2>
                  <p>+88 00 123 456 01</p>
                </div> -->
                <div class="single_contact_info">
                  <h2>Email Me</h2>
                  <p>{{from_email}}</p>
                </div>
                <div class="single_contact_info">
                  <h2>Address</h2>
                  <p>19 Collin Street, Kolkata</p>
                </div>
            </div>
            <div class="col-md-9  wow fadeInRight animated">
                <form class="contact-form" action="">
                  <div class="row">
                      <div class="col-md-6">
                        <input type="text" class="form-control" id="name" placeholder="Name" v-model="enquiry.name">
                        <input type="email" class="form-control" id="email" placeholder="Email" v-model="enquiry.email">
                        <input type="text" class="form-control" id="subject" placeholder="Subject" v-model="enquiry.subject">                                
                      </div>
                      <div class="col-md-6">
                        <textarea class="form-control" id="message" rows="25" cols="10" placeholder="  Message Texts..." v-model="enquiry.message"></textarea>
                        <button type="button" class="btn btn-default submit-btn form_submit" @click="sendEmail">SEND MESSAGE</button>                                
                      </div>
                      <!-- <div class="col-12">
                        <div class="alert alert-primary" role="alert">
                          A simple primary alert—check it out!
                        </div>
                      </div> -->
                      <!-- enquiry_submit_result -->
                  </div>
                </form>
            </div>
          </div>
      </div>
      <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
                <div class="work-with   wow fadeInUp animated">
                  <h3>looking forward to hearing from you!</h3>
                </div>
            </div>
          </div>
      </div>
    </section>

    <footer>
      <div class="container">
          <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                  <div class="footer_logo   wow fadeInUp animated">
                      <img src="images/logo.png" alt="">
                  </div>
                </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
                <div class="col-md-12 text-center   wow fadeInUp animated">
                  <div class="social">
                      <h2>Follow Me on Here</h2>
                      <ul class="icon_list">
                        <li><a href="https://www.facebook.com/krishnendu.ghata"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/krishnendu-ghata-45290050/"><i class="fa fa-linkedin"></i></a></li>
                      </ul>
                  </div>
                </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                  <div class="copyright_text   wow fadeInUp animated">
                      <p>A heart full of thanks to <a href="http://www.themeforest.net/user/thecodecafe" target="_blank">Code Cafe Team</a> for this lovely theam.</p>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </footer>

    <Loading :active.sync="isLoading"></Loading>
  </div>
</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import carousel from 'vue-owl-carousel';
// Import component
import Loading from 'vue-loading-overlay';

export default {
  name: 'Home',
  components: {
    // HelloWorld
    carousel,
    Loading
  },
  data: function () {
    return {
      isLoading: false,
      enquiry: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
      from_email: 'ghata.xyz@gmail.com',
      to_email: 'web.way2krish@gmail.com',
    }
  },
  methods: {
    capitalize: function(s) {
      if(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }
      return ''
    },
    createHtmlTableForEnquiry: function() {
      let txt = '';
      if (this.enquiry.name || this.enquiry.email || this.enquiry.subject || this.enquiry.message) {
        txt += "<table border='1' style='border-spacing: 0px;'>";
        for (let x in this.enquiry) {
          txt += "<tr>"+
            "<td style='width: 80px; padding: 5px;'>" + this.capitalize(x) + "</td>"+
            "<td  style='width: 520px; white-space: pre; padding: 5px;'>" + this.enquiry[x] + "</td>"+
          "</tr>";
        }
        txt += "</table>";   
      }
      return txt;
    },
    sendEmail: function () {
      let enquiry = this.createHtmlTableForEnquiry();
      if(enquiry && enquiry.length > 0) {
        /*global Email:false*/
        this.isLoading = true;
        Email.send({
          SecureToken : "6c1e4ee0-47ec-4179-adfe-85e956ca2d62",
          To : this.to_email,
          From : this.from_email,
          Subject : "A new enquiry on ghata.xyz ["+this.enquiry.name+"]",
          Body : enquiry,
        }).then(message => {
            this.isLoading = false;
            if('OK' === message) {
              this.enquiry.name = '';
              this.enquiry.email = '';
              this.enquiry.subject = '';
              this.enquiry.message = '';

              this.$dialog.alert('Thank you for your interest I will contact you shortly.').then(function() {});
            } else {
              this.$dialog.alert('Oops! looks like something is wrong.').then(function() {});
            }
          }
        );
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .header-intro {
    background: url(/images/header_bg.jpg); 
    background-position-y: -125px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home_text {
    padding-top: 150px;
    padding-bottom: 280px;
  }
</style>